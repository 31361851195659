import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Home from "./component/Home/Home";
import Career from "./component/Career/career";
import Ourprogram from "./component/Ourprogram/Ourprogram";
import About from "./component/About";
import ContactUs from "./component/ContactUs";
import Gallery from "./component/Gallery";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useActingStore } from "./store/useActingStore";

const App = () => {
  const isMobileMenuOpen = useActingStore((s) => s.isMobileMenuOpen);
  return (
    <Router>
      <ToastContainer className="!z-[9999]" />
      <div className="flex flex-col lg:items-center lg:overflow-x-hidden">
        <Navbar />
        <div
          className={`${
            isMobileMenuOpen ? "blur-sm pointer-events-none " : ""
          }`}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ourprogram" element={<Ourprogram />} />
            <Route path="/career" element={<Career />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
