import React, { useEffect, useState } from "react";
import Contact from "./Contact";
import axios from "axios";
import Holistic from "./Holistic";
import { API_BASE_URL } from "../config";
const About = () => {
  const [image, setImage] = useState("");
  const [published, setPublished] = useState(false);
  const getAboutData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getaboutData`);
      setImage(response.data.data?.Photo);
      setPublished(response.data.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAboutData();
  }, []);
  return (
    <div className="lg:pt-0 pt-20">
      <div className="md:hidden lg:mt-[16rem] flex flex-col items-center text-center mt-[2rem]">
        <p className="text-2xl font-bold text-black font- normal mx-6 font-open-sans">
          About
        </p>
        <img
          className="w-[181px] h-[52px] mx-[6rem]"
          src="/about2.png"
          alt="About"
        />
        <img
          className="h-[229px] w-auto mt-[1rem]"
          src="/image1.png"
          alt="ua"
        />
      </div>
      <div
        style={{
          backgroundImage: published
            ? `url(${image})`
            : `url('about-us-banner.png')`,
        }}
        className="w-screen bg-cover hidden lg:flex flex-col items-center justify-center h-screen"
      >
        <div className="hidden lg:mt-[16rem] lg:flex flex-col">
          <p className="text-white text-2xl font- normal mx-6 font-open-sans">
            About
          </p>
          <img className="w-auto h-20" src="/about2.png" alt="About" />
        </div>
      </div>
      <div className="text-center lg:flex lg:flex-col justify-center items-center">
        <h1
          className="text-[#0568EC] text-center tracking-widest text-xl lg:mt-[5rem] mt-[4rem] "
          style={{ fontFamily: "'Permanent Marker', cursive" }}
        >
          Drama is the Future
        </h1>
        <div className="mx-6 lg:w-[63rem] text-justify lg:mt-[3rem]  mt-[2rem]">
          <p className="lg:text-[#414141] text-[#62677F] text-sm lg:text-xl font-normal font-open-sans">
            <span className="text-[#414141] font-bold">Actkidz</span> is a
            unique blend of drama, art, and education that has existed since the
            beginning of time. According to the National Education policy, the
            aim of education will not only be cognitive development, but also
            building character and creating holistic and well-rounded
            individuals equipped with the key 21st Century skills. We believe
            that simply informing a student about a concept is insufficient; a
            good teacher must also instil in a student the ability to think
            critically and the importance of value education. Drama and theatre
            are important outlets for self-expression, and when drama is used as
            a teaching tool, students are involved in all aspects of their
            lives, whether intellectually, physically, socially, or emotionally.
          </p>
          <p className="lg:text-[#414141] text-[#62677F]  text-sm lg:text-xl font-normal font-open-sans mt-[1.4rem]">
            The workshop provides an engaging, fun, and immersive theatre,
            puppetry, mime, characterization, rhythm, space and dance experience
            for your child, aimed at helping them gain confidence on stage and
            as individuals.
          </p>
        </div>
      </div>
      <div>
        <Holistic />
      </div>
      <Contact />
    </div>
  );
};

export default About;
