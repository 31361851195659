import React, { useEffect, useState } from "react";
import Acting from "./acting";
import { useActingStore } from "../store/useActingStore";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";

const Courses2 = () => {
  const navigate = useNavigate();
  const showActing = useActingStore((s) => s.showActing);
  const setShowActing = useActingStore((s) => s.setShowActing);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [activities, setActivities] = useState([
    {
      id: "dance",
      Heading: "ActKidz Wonderland",
      Age: "3-6 years old",
      Image1: "/c1.png",
    },
    {
      id: "rhythm",
      Heading: "ActKidz Adventures ",
      Age: "6 -11 years old",
      Image1: "/c2.png",
    },

    {
      id: "improv",
      Heading: "ActKidz Quest",
      Age: "11-13 years old",
      Image1: "/c4.png",
    },
  ]);
  let testData = [
    {
      id: 1,
      Heading: "",
      Age: "",
      Description: "",
      Image1: "",
      Image2: "",
      Published: false,
    },
  ];
  const [slidesData, setSlidesData] = useState(testData);
  const getFeePrograms = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getprogramData`);
      setSlidesData(response.data.data);
      const data = response.data.data;
      if (Array.isArray(data) && data.length > 0) {
        setActivities(data);
        setSlidesData(data);
      } else {
        setSlidesData(testData);
        setActivities(activities);
      }
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getFeePrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % activities.length);
    }, 3000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(interval);
  }, [activities.length, currentIndex]);
  if (showActing) {
    return <Acting slidesData={slidesData} />;
  }

  const handleClick = () => {
    setShowActing(!showActing);
    navigate("/OurProgram");
  };

  return (
    <div className=" mt-[1.4rem] block lg:hidden overflow-hidden relative">
      <div
        className="whitespace-nowrap transition-transform duration-300"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {activities.map((activity, index) => (
          <div
            key={activity.id}
            className="inline-block w-full h-full space-x-4"
          >
            <div className="mx-auto w-[255px] bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                src={activity.Image1}
                alt={activity.Heading}
                className="w-full h-auto block"
              />
              <div className=" p-3">
                <h1 className="text-xl font-semibold text-[#000] font-open-sans ">
                  {activity.Heading}
                </h1>
                <p className="text-sm mt-[12px] text-[#838383] font-open-sans font-semibold">
                  {activity.Age}
                </p>

                <img
                  className="w-24 h-8 mt-[1rem]"
                  src="/know.png"
                  alt="img"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dots Indicator */}
      <div className="absolute bottom- 0 left-0 right-0 flex justify-center items-center mt-4 p-4">
        {activities.map((_, index) => (
          <span
            key={index}
            className={`inline-block mx-1 ${
              index === currentIndex ? "bg-blue-500" : "bg-gray-300"
            } w-3 h-3 rounded-full`}
          />
        ))}
      </div>
    </div>
  );
};

export default Courses2;
