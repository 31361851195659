import React from "react";
import Course1 from "./course1";
import Courses2 from "./Courses2";

const Courses = () => {
  return (
    <>
      <div>
        <div className="hidden lg:flex flex-col lg:justify-around lg:mx-[22rem] lg:mt-[6rem] mt-[4rem]">
          <p
            className="text-[#0568EC] text-base font-thin"
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            Popular Courses
          </p>
          <h1 className="text-[#000] font-open-sans font-bold text-4xl pb-[2rem]">
            Know about what we offer...
          </h1>
        </div>
        <div>
          <Course1 />
          <div className="hidden lg:flex mb-[105px] justify-center items-center text-center mt-[5rem] space-x-4">
            <button className="flex items-center text-black text-sm font-open-sans font-semibold p-4 rounded-full border-2 border-black">
              <span
                className="tracking-widest font-thin text-2xl"
                style={{ fontFamily: "'Permanent Marker', cursive" }}
              >
                3+
              </span>
              <span className="text-xl ml-2">Programs for your kids </span>
              <a href="/ourprogram">
                <span className="ml-[1rem] text-white font-open-sans text-base rounded-full bg-[#0568EC] px-6 py-2">
                  Explore all Programs
                </span>
              </a>
            </button>
            <div>
              <img src="/c5.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="lg:hidden mx-2">
          <h1 className="text-center mt-[5rem]  text-[#000] font-open-sans font-bold text-3xl  ">
            Know about what we offer
          </h1>
          <p
            className=" mt-[1.2rem] text-[#0568EC] text-center font-bold text-sm "
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            3+ Programs for your kids
          </p>
        </div>
        <div>
          <Courses2 />
        </div>
      </div>
    </>
  );
};

export default Courses;
