import React, { useEffect, useState } from "react";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";
import { useActingStore } from "../store/useActingStore";

const ContactUs = () => {
  const [image, setImage] = useState("");
  const address = useActingStore((s) => s.address);
  const setAddress = useActingStore((s) => s.setAddress);
  const [published, setPublished] = useState(false);
  const getAboutData = async () => {

    try {
      const response = await axios.get(`${API_BASE_URL}/getcontactpageData`);
      setImage(response.data.data?.Photo);
      setAddress(response.data.data?.Address);
      setPublished(response.data.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAboutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  async function sendContactData() {
    if (!name.trim() || !number.trim() || !email.trim()) {
      return toast.error("All fields are required");
    }
    if (!/^\d{10}$/.test(number)) {
      return toast.error("Phone number must be numeric and exactly 10 digits");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return toast.error("Please enter a valid email address");
    }

    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(name) || !nameRegex.test(name)) {
      return toast.error("Name  must contain only letters");
    }


    try {
      const payload = {
        Name: name,
        Mobile: number,
        Email: email,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${API_BASE_URL}/userContactData`,
        payload,
        config
      );
      if (response.data.status) {
        toast.success("Email sent successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  return (
    <div className="pt-[8rem] md:pt-32 lg:flex lg:flex-col lg:items-center lg:justify-center">
      <div className="lg:space-x-32 flex flex-row justify-center lg:mt-[4rem]">
        <div className="flex flex-col space-y-4 lg:w-[34rem]">
          <h1
            className="flex justify-center text-4xl font-bold tracking-widest text-black lg:mx-0 lg:justify-start"
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            Get in{" "}
            <span
              className="text-[#0568EC] tracking-widest px-2"
              style={{ fontFamily: "'Permanent Marker', cursive" }}
            >
              Touch
            </span>
          </h1>
          <p className="text-[#000] lg:mx-0 mx-[1.4rem] text-sm font-normal font-open-sans">
            Thank you for your interest in ActKidz! We're thrilled to connect
            with you and explore how we can collaborate to ignite creativity and
            inspire young minds through the art of theatre.
          </p>
          <div>
            <div className="flex flex-col space-y-6 lg:mx-0 mx-[2rem] lg:w-[34rem]">
              <div className="flex flex-col ">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  className="lg:w-[34rem] lg:px-6 py-2 px-4  lg:rounded-full rounded-lg  border  border-[#E0E0E0] bg-[#F7F7F7] "
                />
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Phone Number"
                  className="lg:px-6 py-2 px-4 lg:rounded-full rounded-lg border  border-[#E0E0E0]  bg-[#F7F7F7]"
                />
              </div>
              <div className="flex flex-col ">
                <input
                  type="text"
                  id="Email"
                  name="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="lg:px-6 py-2 px-4 lg:rounded-full rounded-lg  border  border-[#E0E0E0] bg-[#F7F7F7] "
                />
              </div>
              <div>
                <button
                  onClick={sendContactData}
                  type="submit"
                  className="bg-black lg:w-full w-full lg:px-0 px-[8rem] py-2 text-white font-bold lg:rounded-full rounded-lg transition duration-300"
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
          <div className="flex-row justify-center hidden lg:flex lg:space-x-8 lg:mx-0 lg:justify-start">
            <div className="lg:mt-[2.5rem] flex flex-row items-center space-x-4">
              <div style={{ width: "28px", height: "28px" }}>
                <LuPhoneCall size={28} />
              </div>
              <div className="flex flex-col w-[6.25rem]">
                <p className="text-base font-semibold text-black font-open-sans">
                  Phone
                </p>
                <p className="text-[#0568EC] text-xs font-normal w-full">
                  +91 8686983890
                </p>
              </div>
            </div>
            <div className="lg:mt-[2.5rem] flex flex-row items-center space-x-4">
              <MdOutlineMail size={28} />
              <div className="flex flex-col">
                <p className="text-base font-semibold text-black font-open-sans">
                  EMAIL
                </p>
                <p className="text-[#0568EC] text-base font-normal">
                  info@actkidz.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <img
            className="h-[23rem]"
            src={image && published ? image : "/contact.png"}
            alt="img"
          />
        </div>
      </div>
      <p className="lg:hidden  text-[#1B1E2B] text-3xl font-open-sans font-semibold mt-[2rem] text-center">
        Our location & Contact
      </p>
      <div className="flex flex-row justify-center my-6 lg:hidden lg:space-x-8 lg:mx-0 lg:justify-start">
        <div className="lg:mt-[2.5rem] flex flex-row items-center space-x-4">
          <div style={{ width: "28px", height: "28px" }}>
            <LuPhoneCall size={28} />
          </div>
          <div className="flex flex-col w-[6.25rem]">
            <p className="text-base font-semibold text-black font-open-sans">
              Phone
            </p>
            <p className="text-[#0568EC] text-xs font-normal w-full">
              +91 8686983890
            </p>
          </div>
        </div>
        <div className="lg:mt-[2.5rem] flex flex-row items-center space-x-4">
          <MdOutlineMail size={28} />
          <div className="flex flex-col">
            <p className="text-base font-semibold text-black font-open-sans">
              EMAIL
            </p>
            <p className="text-[#0568EC] text-base font-normal">
              info@actkidz.com
            </p>
          </div>
        </div>
      </div>
      <div className=" w-full h-[260px] lg:w-[64rem] lg:h-[281px] overflow-hidden rounded-lg lg:mx-[10rem] lg:mt-[5rem] mt-[4rem]">
        <iframe
          title="Location Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.7618468630934!2d78.56658171529582!3d17.488966970494495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9d8239e394ed%3A0xa89127946c40606b!2sACTKIDZ+PRESCHOOL!5e0!3m2!1sen!2sin!4v1657187470651!5m2!1sen!2sin"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0, borderRadius: "0.5rem" }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      <p className="hidden lg:block text-[#1B1E2B] text-center font-open-sans text-2xl lg:mt-[5rem] lg:mx-[14rem]">
        {address && published
          ? address
          : "Surya PPR Towers, Plot No 13,14,  Sri Guru Raghavendra Colony, Saket Road, Kapra, Secunderabad, Telangana 500062"}
      </p>
      <div
        className="w-screen lg:h-[221px]"
        style={{
          background:
            "linear-gradient(0deg, #C9D9EC 0%, rgba(217, 217, 217, 0.00) 102.29%)",
        }}
      ></div>
    </div>
  );
};

export default ContactUs;
