import React, { useEffect, useState } from "react";
import Holistic from "./Holistic";
import { useActingStore } from "../store/useActingStore";

const Acting = () => {
  const filteredData = useActingStore((s) => s.filteredData);
  const [slideData, setSlideData] = useState({});
  useEffect(() => {
    setSlideData(filteredData);
    console.log(filteredData);
  }, [filteredData]);
  return (
    <>
      <div className="hidden lg:flex flex-col items-center text-center md:pt-16 pt-10 mb-24">
        <h1
          className="text-center tracking-widest text-[#000] text-4xl font-normal mt-[4rem] "
          style={{ fontFamily: "'Permanent Marker', cursive" }}
        >
          {slideData && slideData?.Published
            ? slideData?.Heading
            : "ActKidz Adventures"}
        </h1>
        <div className="relative hidden md:flex mt-[1.8rem] lg:my-[6rem] text-center">
          <div className="flex flex-col justify-end">
            <img
              src={
                slideData?.Image1 && slideData?.Published
                  ? slideData.Image1
                  : "/i1.svg"
              }
              alt="img"
              className="-rotate-12 absolute -left-12 -top-10 -z-10"
            />
            <img src="/i2.svg" alt="img" />
          </div>
          <div>
            <img
              src={
                slideData?.Image2 && slideData?.Published
                  ? slideData.Image2
                  : "/i3.svg"
              }
              alt="img"
              className="z-10"
            />
          </div>
          <div>
            <img
              src="/i4.svg"
              alt="img"
              className="-mt-[5rem] -ml-[8rem] w-[80px] h-[80px]"
            />
            <div className="-mt-[2rem] -ml-[5rem] relative">
              <img src="/i5.png" alt="img" className="w-[150px] h-[150px]" />
              <p className="absolute top-8 left-8 bg-white w-[5rem] h-[3rem]">
                {slideData?.Age}
              </p>
            </div>
            <img src="/c5.png" alt="img" />
          </div>
        </div>
        <div className="block md:hidden">
          <div className="lg:relative md:flex mt-[1.8rem] text-center">
            <div className="flex flex-col justify-end">
              <img
                src={
                  slideData?.Image1 && slideData?.Published
                    ? slideData.Image1
                    : "/i1.svg"
                }
                alt="img"
                className="absolute lg:bottom-32 top-1/2 z-10 -mt-[10rem] lg:-mt-[5rem]  -rotate-12 lg:absolute lg:-left-12 lg:-top-10 lg:-z-10 w-[134px] h-[174px]"
              />
              <img
                src="/i2.svg"
                alt="img"
                className="w-4 h-4 lg:w-full lg:h-full"
              />
            </div>
            <div>
              <img
                src={
                  slideData?.Image2 && slideData?.Published
                    ? slideData.Image2
                    : "/i3.svg"
                }
                alt="img"
                className="z-10"
              />
            </div>
            <div className="lg:hidden relative flex justify-around">
              <img
                src="/i0.png"
                alt="img"
                className="ml-autolg:-mt-[5rem] lg:-ml-[10rem] lg:w-[80px] lg:h-[80px]"
              />
              <div className="flex justify-center lg:-mt-[2rem] lg:-ml-[5rem] lg:relative">
                <img
                  src="/i5.png"
                  alt="img"
                  className="absolute left-1/3 lg:w-[150px] lg:h-[150px] w-[114px] h-[114px]"
                />
                <p className="absolute top-8  left-8 bg-white lg:w-[5rem] lg:h-[3rem]"></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-center text-[#62677F] h-[324px] md:h-[10rem] w-[98vw] lg:text-[#000] font-open-sans lg:text-sm text-xs font-bold  lg:font-medium md:space-y-8 "
          style={{
            background:
              "linear-gradient(0deg, #C9D9EC 0%, rgba(217, 217, 217, 0.00) 102.29%)",
          }}
        >
          <div className="text-start lg:text-center md:mx-[12vw] lg:my-10 mx-6 lg:space-y-4 space-y-6">
            <p className="leading-8">
              {slideData
                ? slideData?.Description
                : `This includes a variety of confidence-building drama games
              and exercises, such as focus work, character
              communication, and vocal projection.
              The child will then move on to more detailed performance
              notes on character, physicality, and accents, among other
              things.
              This session will greatly aid in the improvement of
              children's attention at school by encouraging
              concentration, imagination, and speaking skills in a fun
              and imaginative manner.`}
            </p>
          </div>
        </div>
        <div>
          <Holistic />
        </div>
      </div>
    </>
  );
};

export default Acting;
