import React, { useEffect, useState } from "react";
import Acting from "./acting";
import { useActingStore } from "../store/useActingStore";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";

const Course1 = () => {
  const navigate = useNavigate();
  const showActing = useActingStore((s) => s.showActing);
  const setShowActing = useActingStore((s) => s.setShowActing);
  const [activities, setActivities] = useState([
    {
      id: "dance",
      Heading: "ActKidz Wonderland",
      Age: "3 yrs - 6 yrs",
      Image1: "/c1.png",
    },
    {
      id: "rhythm",
      Heading: "ActKidz Adventures ",
      Age: "6 yrs - 11 yrs",
      Image1: "/c2.png",
    },

    {
      id: "improv",
      Heading: "ActKidz Quest",
      Age: "11 yrs - 13 yrs",
      Image1: "/c4.png",
    },
  ]);
  let testData = [
    {
      id: 1,
      Heading: "",
      Age: "",
      Description: "",
      Image1: "",
      Image2: "",
      Published: false,
    },
  ];
  const [slidesData, setSlidesData] = useState(testData);
  const setNavState = useActingStore((s) => s.setNavState);
  const getFeePrograms = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getprogramData`);
      setSlidesData(response.data.data);
      const data = response.data.data;
      if (Array.isArray(data) && data.length > 0) {
        setActivities(data);
        setSlidesData(data);
      } else {
        setSlidesData(testData);
        setActivities(activities);
      }
    } catch (e) {
      console.log("err", e);
    }
  };
  const [showData, setShowData] = useState("");
  useEffect(() => {
    getFeePrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilteredData = useActingStore((s) => s.setFilteredData);
  useEffect(() => {
    if (showActing) {
      const filteredData = slidesData.find((slide) => slide?.id === showData);
      console.log(filteredData);
      if (filteredData) {
        setFilteredData(filteredData);
        console.log(filteredData);
      }
      setNavState({ activeNav: "OurPrograms", showImage: true });
      navigate("/OurProgram");
      window.scroll({ top: 0, behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActing, slidesData]);
  const handleClick = (id) => {
    setShowData(id);
    setShowActing(!showActing);
  };

  return (
    <>
      {showActing ? (
        <Acting />
      ) : (
        <div
          className="hidden lg:flex lg:justify-center"
          style={{
            backgroundImage: "url(/course.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex flex-wrap">
            {activities.map((activity) => (
              <div
                onClick={() => handleClick(activity?.id)}
                key={activity.id}
                className="w-[16rem] hover:scale-110 hover:transition-all cursor-pointer h-[18rem] rounded-lg m-4 shadow-lg bg-white"
              >
                <img
                  src={activity.Image1}
                  alt={activity.Heading}
                  className="rounded-t-lg w-full h-1/2"
                />
                <div className="p-3">
                  <h1 className="text-xl text-[#000] font-open-sans font-semibold">
                    {activity.Heading}
                  </h1>
                  <p className="text-sm text-[#838383] font-open-sans font-bold lg:mt-[0.6rem]">
                    {activity.Age}
                  </p>
                  <img
                    className="w-24 h-8 lg:mt-[2rem] cursor-pointer"
                    src="/know.png"
                    alt="Learn more"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Course1;
