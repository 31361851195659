import React from "react";
import Programs1 from "../programs1";

const Ourprogram = () => {
  return (
    <div>
      <Programs1 />
    </div>
  );
};

export default Ourprogram;
