import React from "react";
import Career1 from "../career1";
import Contact from "../Contact";

const Career = () => {
  return (
    <div className="pt-20 md:pt-32">
      <Career1 />
      <Contact />
    </div>
  );
};

export default Career;
