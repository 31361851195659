import React, { Fragment } from "react";
import { RxCross2 } from "react-icons/rx";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({ selectedItem, modalOpen, closeModal }) => {
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div
            className="fixed inset-0 backdrop-blur-sm"
            onClick={closeModal}
          />
          <div className="fixed inset-0 overflow-y-auto" onClick={closeModal}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="fixed inset-0 overflow-y-auto flex items-center justify-center p-4 text-center">
                  <div className="space-y-6 w-full lg:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="flex justify-between text-lg font-medium leading-6 text-gray-900"
                    >
                      <div className="flex space-x-4 lg:space-x-10 justify-center items-center">
                        <div className="flex items-center justify-center rounded-full bg-blue-100">
                          <img
                            src={selectedItem?.imgSrc}
                            alt={selectedItem?.label}
                            className="h-[3rem] w-[3rem] lg:h-[5rem] lg:w-[5rem]"
                          />
                        </div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {selectedItem?.label}
                        </h3>
                      </div>
                      <div
                        onClick={closeModal}
                        className="lg:flex items-center mt-3 lg:mt-0"
                      >
                        <button>
                          <RxCross2 size="24" />
                        </button>
                      </div>
                    </Dialog.Title>
                    <div className="lg:mt-2 lg:py-3">
                      <p className="text-sm lg:text-base text-gray-500 text-justify leading-6 lg:leading-8">
                        {selectedItem?.description}
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
