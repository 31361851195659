import React from "react";

const Contact = () => {
  return (
    <>
      <div className="lg:flex lg:flex-col pt-[2rem] text-center items-center justify-center bg-white">
        <div className="hidden lg:block">
          <p className="font-jakarta text-[#1B1E2B] text-center text-3xl font-normal">
            The classes are led by experienced actors who serve as teachers
          </p>
        </div>
        <p className="block md:hidden text-[#1B1E2B] text-4xl font-jakarta mt-[4rem] font-semibold">
          Connect with us!!
        </p>
        <h1 className="lg:w-[52rem] leading-6 mx-4 lg:mx-0 text-[#62677F] text-center font-jakarta lg:text-sm  text-base  mt-[2rem] lg:mt-[1.2rem] font-normal">
          In our classes, we have experienced actors as teachers, and we offer
          acting courses with practical training and support, allowing kids to
          learn acting much more quickly.
        </h1>
        <div className="hidden lg:flex self-center lg:ml-[20rem]">
          <img className="w-[8rem] h-[8rem]" src="/contact.svg" alt="img" />
        </div>
      </div>
      <div
        style={{
          maxWidth: "100%",
          background:
            "linear-gradient(0deg, #C9D9EC 0%, rgba(217, 217, 217, 0.00) 102.29%)",
        }}
        className="flex flex-row justify-center lg:mt-0 mt-[5rem] pb-[100px] w-screen"
      >
        <a href="/ContactUs">
          <button className="text-center text-white lg:px-8 lg:py-2 px-32 py-4 lg:rounded-full rounded-lg bg-[#191E24]">
            Contact us
          </button>
        </a>
      </div>
    </>
  );
};

export default Contact;
