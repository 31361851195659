import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
const Body = () => {
  const [scaleImage, setScaleImage] = useState(true);
  const [images, setImages] = useState([
    "./image1.png",
    "./image2.png",
    "./image3.png",
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const getBodyData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getData`);
      const Photos = response?.data?.data?.Photos;
      if (Array.isArray(Photos) && Photos.length > 0) {
        const imageArray = Photos?.map((eachPhoto) => eachPhoto.image);
        setImages(imageArray);
      } else {
        const defaultPhotos = ["./image1.png", "./image2.png", "./image3.png"];
        setImages(defaultPhotos);
      }
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getBodyData();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setScaleImage(true);
      setTimeout(() => {
        setScaleImage(false);
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="lg:relative lg:w-full lg:h-auto w-auto h-[250px] lg:mt-[1.2rem]">
      <div className="hidden lg:block ">
        <div className="w-screen h-full">
          <img
            id="slideshow-image"
            src={images[currentIndex]}
            alt="Slideshow"
            className={`w-screen h-full ${
              scaleImage
                ? "scale-110 ease-in-out duration-500"
                : "scale-100 ease-in-out duration-500"
            }`}
          />
        </div>

        <button
          onClick={handlePrevious}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 ml-[3rem] "
        >
          <span className="text-xl text-white">&#60;</span> {/* Left arrow */}
        </button>

        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 mr-[3rem]"
        >
          <span className="text-xl text-white">&#62;</span> {/* Right arrow */}
        </button>

        <div className="hidden lg:block absolute bottom-10 w-full text-center p-4 leading-10  ">
          <p className="text-[#DCDCDC] text-center text-4xl font-bold font-open-sans">
            Elevate your child's confidence <br />
            with expressive skills for a{" "}
            <span
              className="text-[#3289FB] font-normal tracking-widest text-4xl  "
              style={{ fontFamily: "'Permanent Marker', cursive" }}
            >
              Brighter Future
            </span>
          </p>
        </div>
      </div>
      <div className="block lg:hidden mx-4">
        <p className="text-[#1B1E2B] text-center font-open-sans font-medium text-3xl mt-[2rem]">
          Elevate your child's expressive skills for a
        </p>
        <h1
          className="text-[#3289FB] tracking-widest font-normal text-3xl text-center"
          style={{ fontFamily: "'Permanent Marker', cursive" }}
        >
          Brighter Future
        </h1>
      </div>
      <div className="lg:hidden h-[21rem] mt-[3rem] pb-[3rem]">
        <img
          src={images[currentIndex]}
          alt="Slideshow"
          //className="h-full w-full object-cover object-center hover:scale-105 hover:transition-all"
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          className={`object-cover object-center w-full h-full ${
            scaleImage
              ? "scale-110 ease-in-out duration-500"
              : "scale-100 ease-in-out duration-500"
          }`}
        />
      </div>
    </div>
  );
};

export default Body;
