import React, { useState } from "react";
import Holistic from "./Holistic";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";
const Heighlight = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [query, setQuery] = useState("");
  async function saveUserData(e) {
    if (!name.trim() || !number.trim() || !query.trim()) {
      return toast.error("All fields are required");
    }
    if (!/^\d{10}$/.test(number)) {
      return toast.error("Phone number must be numeric and exactly 10 digits");
    }

    if (query.length > 200) {
      return toast.error("Message cannot be more than 100 characters long");
    }
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(name) || !nameRegex.test(name)) {
      return toast.error("Name  must contain only letters");
    }
    try {
      const payload = {
        Name: name,
        Mobile: number,
        Query: query,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${API_BASE_URL}/userHomeData`,
        payload,
        config
      );
      if (response.data.status) {
        toast.success("Email sent successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  return (
    <div className="bg-[#f5f7fa]">
      <Holistic />
      <div
        className="hidden lg:block pt-[10rem]"
        style={{
          background:
            "background: linear-gradient(0deg, rgba(230, 240, 254, 0.5) 0%, rgba(217, 217, 217, 0) 102.29%)",
        }}
      >
        <div
          className="flex flex-col justify-between max-w-3xl px-10 pt-10 mx-auto text-white md:flex-row rounded-xl"
          style={{
            background:
              "linear-gradient(103deg, #0568EC 5.34%, #10A6C7 81.31%)",
          }}
        >
          <div className="flex-col items-center hidden md:flex md:items-start">
            <img className="w-[131px] h-[131px]" src="/email.svg" alt="img" />
            <h2 className="mt-6 mb-4 text-2xl font-bold">Get in touch now!</h2>
            <p
              className="mt-2 mb-6 text-xs font-bold tracking-widest"
              style={{ fontFamily: "'Permanent Marker', cursive" }}
            >
              Do you think every kid is unique and got skills in our bones,
              talents to explore?
            </p>
          </div>
          <div className="flex-col hidden ml-16 space-y-4 md:flex">
            <div className="flex flex-col ">
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                placeholder="Name"
                className="px-6 py-2 text-black rounded-full "
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                id="phone"
                name="phone"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Phone Number"
                className="px-6 py-2 text-black rounded-full"
              />
            </div>
            <div className="flex flex-col">
              <textarea
                id="query"
                name="query"
                rows="4"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Write your query"
                className="px-6 py-2 text-black rounded-md"
              ></textarea>
            </div>
            <div>
              <button
                onClick={saveUserData}
                className="px-8 py-2 font-bold text-white transition duration-300 bg-black rounded-full"
              >
                Submit
              </button>
              <div>
                <img src="/text.png" alt="img" />
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col justify-between max-w-4xl p-10 mx-auto text-white lg:hidden md:flex-row"
        style={{
          background:
            "linear-gradient(180deg, #0568EC 0.02%, rgba(5, 104, 236, 0.00) 90.83%)",
        }}
      >
        <div className="z-20 flex flex-col ">
          <p className="mt-2 text-sm font-bold text-center text-white font-open-sans">
            WE LOVE TO SEE YOU GROW
          </p>
          <h2 className="mt-6 text-3xl font-bold text-center">
            Get in touch now!
          </h2>
          <p className="mt-4 text-lg font-normal text-center text-white font-open-sans">
            Do you think every kid is unique and got his or her own talent to
            explore?
          </p>
          <img src="/p0.png" alt="img" />
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col ">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              className="px-6 py-2 text-black rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              className="px-6 py-2 text-black rounded-lg"
            />
          </div>
          <div className="flex flex-col">
            <textarea
              id="query"
              name="query"
              rows="4"
              placeholder="Write your query"
              className="px-6 py-2 text-black rounded-md"
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              onSubmit={(e) => saveUserData(e)}
              className="bg-black mt-4 text-white font-bold py-2 lg:px-[7.4rem] w-full rounded-lg transition duration-300"
            >
              Submit
            </button>
            <div className="hidden lg:inline-flex">
              <img src="/text.png" alt="img" />
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heighlight;
