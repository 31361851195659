import React from "react";
import Course1 from "./course1";
import Contact from "./Contact";
import Courses2 from "./Courses2";
import { useActingStore } from "../store/useActingStore";

const Programs1 = () => {
  const showActing = useActingStore((s) => s.showActing);
  return (
    <>
      {!showActing && (
        <div className="pt-20 md:pt-32 flex flex-col items-center text-center mt-6">
          <img
            className="hidden lg:block w-84 h-84 rotate-12"
            src="/c5.png"
            alt="img"
          />
          <p className="text-[#0568EC] font-open-sans text-4xl font-normal mt-4">
            Unlocking Confidence and Expression
          </p>
          <h1
            className="text-black text-2xl tracking-widest font-normal lg:mt-2 mt-8"
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            The Transformative Power of Acting for Children
          </h1>
        </div>
      )}
      <div className="hidden lg:block">
        <div className="flex justify-center items-center space-x-4 lg:mt-8 mt-10 ">
          {!showActing && (
            <img
              src="/s1.svg"
              alt="First Symbol"
              className="w-[227px] h-[129px]"
            />
          )}
          <Course1 />
          {!showActing && (
            <img
              src="/s2.svg"
              alt="Second Symbol"
              className="w-[253px] h-[146px]"
            />
          )}
          {/* Adjust size as needed */}
        </div>
        <div className="">
          <Contact />
        </div>
      </div>
      <div className="block lg:hidden">
        <Courses2 />
        {/* <p className="text-center  text-[#1B1E2B] text-4xl font-jakarta mt-[4rem] font-semibold">
          Connect with us!!
        </p> */}
        <Contact />
      </div>
    </>
  );
};

export default Programs1;
