import React from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useActingStore } from "../store/useActingStore";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const setShowActing = useActingStore((s) => s.setShowActing);
  const setIsMobileMenuOpen = useActingStore((s) => s.setIsMobileMenuOpen);
  const isMobileMenuOpen = useActingStore((s) => s.isMobileMenuOpen);
  const navState = useActingStore((s) => s.navState);
  const setNavState = useActingStore((s) => s.setNavState);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  // Function to handle nav item click
  const handleNavClick = (navItem) => {
    setShowActing(false);
    if (navState.activeNav !== navItem) {
      setNavState({ activeNav: navItem, showImage: true });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navImages = {
    Home: "H1.png",
    OurPrograms: "H2.png",
    Gallery: "H3.png",
    Career: "H4.png",
    AboutUs: "H5.png",
    ContactUs: "H6.png",
  };
  return (
    <div
    className={`md:w-[69rem] w-full fixed z-50 px-6 items-center text-center bg-white lg:bg-white/60 py-4 top-0 lg:top-6  border lg:rounded-3xl flex flex-wrap justify-between`}
      style={{
        background: "#FFF",
        // boxShadow: "0px 3.84141px 20.35947px 0px rgba(5, 104, 236, 0.13)",
      }}
    >
      <Link to="/" onClick={() => handleNavClick("Home")}>
        {!isMobileMenuOpen && <img className="" src="/logo.png" alt="logo" />}
      </Link>

      {/* Desktop Menu */}
      <div className="hidden lg:flex lg:items-center text-[#2A2A2A] font-open-sans font-normal text-center text-base space-x-16">
        <Link to="/" onClick={() => handleNavClick("Home")}>
          {navState.activeNav === "Home" && navState.showImage ? (
            <img src={navImages.Home} alt="Home" />
          ) : (
            "Home"
          )}
        </Link>

        <Link to="/ourprogram" onClick={() => handleNavClick("OurPrograms")}>
          {navState.activeNav === "OurPrograms" && navState.showImage ? (
            <img src={navImages.OurPrograms} alt="Our Programs" />
          ) : (
            "Our Programs"
          )}
        </Link>
        <Link to="/gallery" onClick={() => handleNavClick("Gallery")}>
          {navState.activeNav === "Gallery" && navState.showImage ? (
            <img src={navImages.Gallery} alt="Gallery" />
          ) : (
            "Gallery"
          )}
        </Link>
        <Link to="/career" onClick={() => handleNavClick("Career")}>
          {navState.activeNav === "Career" && navState.showImage ? (
            <img src={navImages.Career} alt="Career" />
          ) : (
            "Career"
          )}
        </Link>
        <Link to="/about" onClick={() => handleNavClick("About Us")}>
          {navState.activeNav === "About Us" && navState.showImage ? (
            <img src={navImages.AboutUs} alt="About Us" />
          ) : (
            "About Us"
          )}
        </Link>
        <Link to="/contactUs" onClick={() => handleNavClick("Contact Us")}>
          {navState.activeNav === "Contact Us" && navState.showImage ? (
            <img src={navImages.ContactUs} alt="Contact Us" />
          ) : (
            "Contact Us"
          )}
        </Link>
      </div>

      {/* Mobile Menu Toggle Button */}
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="lg:hidden w-10 h-10 px-3 text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      >
        <GiHamburgerMenu className="stroke-2" />
      </button>

      {/* Mobile Menu */}
      <div
        className={`lg:hidden absolute top-[5rem] w-64 bg-gray-50 shadow-lg transition-transform transform ${
          isMobileMenuOpen ? "right-0 rounded-xl" : "translate-x-full"
        }`}
      >
        {isMobileMenuOpen && (
          <ul className="flex p-4 bg-white z-50 w-full rounded-xl">
            <div>
              <div>
                <img src="/logo.png" alt="im" />
              </div>
            </div>
            <div className="flex flex-col justify-end text-right">
              <li className="flex self-end py-2 pl-3 text-[#2A2A2A] rounded">
                <RxCross2 size={24} onClick={toggleMobileMenu} />
              </li>
              <li>
                <a href="/" className="block py-2 pl-3 text-[#2A2A2A] rounded">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/ourprogram"
                  className="block py-2 pl-3 text-[#2A2A2A] rounded"
                >
                  Our Programs
                </a>
              </li>
              <li>
                <a
                  href="/gallery"
                  className="block py-2 pl-3 text-[#2A2A2A] rounded"
                >
                  Gallery
                </a>
              </li>
              <li>
                <a
                  href="/career"
                  className="block py-2 pl-3 text-[#2A2A2A] rounded"
                >
                  Career
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="block py-2 pl-3 text-[#2A2A2A] rounded"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/contactUs"
                  className="block py-2 pl-3 text-[#2A2A2A] rounded"
                >
                  Contact Us
                </a>
              </li>
            </div>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Navbar;
