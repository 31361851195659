import React, { useEffect, useState } from "react";
import { MdArrowOutward } from "react-icons/md";
import axios from "axios";
import { API_BASE_URL } from "../config";

const Career1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
    }, 3000);

    return () => clearInterval(interval);
  });
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const getHomeData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcareerImageData`);
      setSelectedImage(response.data.data?.Photos);
      setToggleSwitch(response?.data?.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getHomeData();
    getHomeLink();
  }, []);
  const [slidesData, setSlidesData] = useState([
    {
      id: "dance",
      Heading: "Skill set or JD for Hiring",
      Photos: "/A1.png",
    },
  ]);
  const getHomeLink = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcareerData`);
      const fetchedData = response.data.data;
      const initialSwitchState = {};
      fetchedData.forEach((slide) => {
        initialSwitchState[slide.id] = slide.Published;
      });
      setSlidesData(response.data.data);
      const data = response.data.data;
      if (Array.isArray(data) && data.length > 0) {
        setSlidesData(data);
      } else {
        const defaultData = [
          {
            id: "dance",
            Heading: "Skill set or JD for Hiring",
            Photos: "/A1.png",
          },
        ];
        setSlidesData(defaultData);
      }
      setToggleSwitch(initialSwitchState);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getHomeLink();
  }, []);

  return (
    <>
      <div className="flex flex-row justify-around md:mx-20">
        <div>
          <p
            className="text-black tracking-widest text-2xl font-normal lg:mt-[6rem] mt-[2rem] mx-10 lg:mb-0 mb-[4rem]"
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            Work with us
          </p>
          <div
            className="mr-4 relative lg:w-1/2 block lg:hidden"
            style={{
              backgroundImage: "url(/course.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              className="absolute top-36 z-10 mt-[2rem]"
              src="img1.png"
              alt="img"
            />
            <img
              className="absolute lg:-right-6 -right-0"
              src="img2.png"
              alt="img"
            />
          </div>
          <div>
            <h1 className="lg:mt-[1rem] mt-[40rem] mx-10 text-[#414141] font-open-sans text-lg font-normal lg:text-center">
              <span className="text-[#414141] font-bold">If you're ready</span>{" "}
              to embark on a fulfilling career with ActKidz,
              <br />
              explore our current job openings and submit your application.
            </h1>
            <div className="lg:mx-10 mt-[4rem] flex flex-col items-center lg:items-start">
              <a
                href="/ContactUs"
                className="text-center font-bold lg:mb-[89px] text-white lg:px-12 lg:py-2 px-28 py-2 lg:rounded-full rounded-lg bg-[#191E24] mt-[5rem]"
              >
                <button>Contact us</button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="w-1/4 relative hidden mr-10 lg:flex mt-[2rem] "
          style={{
            backgroundImage: "url(/course.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            className="absolute -left-28 z-10"
            src="left-swirl.png"
            alt="img"
          />
          <img
            className="absolute bottom-20 -left-24 z-10"
            src={
              selectedImage[0] && toggleSwitch
                ? selectedImage[0]?.image
                : "img1.png"
            }
            alt="img"
          />
          <img
            className="absolute "
            src={
              selectedImage[1] && toggleSwitch
                ? selectedImage[1]?.image
                : "img2.png"
            }
            alt="img"
          />
          <img
            className="rotate-12 absolute -bottom-16 left-24 -z-10"
            src="right-swirl.png"
            alt="img"
          />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="lg:mt-[12rem] mx-10 mt-[4rem]">
          <h1
            className="text-black font-normal tracking-widest text-4xl lg:mx-0 mx-8  "
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            Careers
          </h1>
          <div className="lg:hidden overflow-hidden relative lg:mt-[2rem] mt-[3rem]">
            <div
              className="whitespace-nowrap transition-transform"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slidesData.map((activity, index) => (
                <div key={activity.id} className="inline-flex w-full h-full">
                  <div className=" bg-white rounded-lg overflow-hidden shadow-lg">
                    <img
                      src={activity.Photos}
                      alt={activity.Heading}
                      //className="w-full object-cover"
                    />
                    <div className="p-3 flex-1 flex flex-col justify-between">
                      <div className="text-center flex flex-row space-x-6 ">
                        <a
                          className="flex"
                          href={"https://" + activity?.Link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h1 className="text-xl text-[#000] font-semibold">
                            {activity.Heading}
                          </h1>
                          <MdArrowOutward className="text-black mt-[0.6rem] " />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Dots Indicator */}
            <div className="absolute bottom-0 left-0 right-0 flex justify-center lg:p-4 ">
              {slidesData.map((_, index) => (
                <span
                  key={index}
                  className={`inline-block mx-1 ${
                    index === currentIndex ? "bg-blue-500" : "bg-gray-300"
                  } w-3 h-3   rounded-full`}
                />
              ))}
            </div>
          </div>
          <div className="mt-[1.4rem] text-[#414141] font-open-sans ">
            <p className="lg:mt-0 mt-[1rem]">
              At <span className="font-bold text-[#414141]">ActKidz</span>, we
              don't just offer jobs; we invite individuals to join a
              professional community deeply committed to the highest standards
              of teaching and learning.
            </p>
            <p className="lg:mt-0 mt-[1rem]">
              If you're driven by a passion for shaping young minds and
              embodying the transformative power of theatre, ActKidz is the
              place for you.
            </p>
            <h1 className="mt-6">
              We invite you to learn more about our school and explore our
              current openings.
            </h1>
          </div>
          <div className="hidden md:flex flex-wrap mt-[2rem] ">
            {slidesData.map((activity) => (
              <div
                key={activity.id}
                className="mx-2 flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden"
                style={{ width: "18rem" }} // Adjust the width as needed
              >
                <img
                  src={activity.Photos}
                  alt={activity.Heading}
                  className="w-full h-48 object-cover" // Adjust height as needed
                />
                <div className="py-5 flex flex-col justify-between">
                  <div className="text-center flex flex-row">
                    <a
                      href={"https://" + activity?.Link}
                      target="_blank"
                      rel="noreferrer"
                      className="flex justify-between space-x-6 items-center "
                    >
                      <h1 className="text-xl text-[#000] font-semibold">
                        {activity.Heading}
                      </h1>
                      <MdArrowOutward size={20} className="text-black" />
                    </a>
                  </div>
                  {/* <div className="flex flex-row  px-4 py-3">
                  <span className="text-xl font-semibold text-red-600">
                    {activity.number}
                    <MdArrowOutward className="text-black" />
                  </span>
                </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Career1;
