import React from "react";
import { TiTick } from "react-icons/ti";

const Program = () => {
  return (
    <>
      <div className="flex flex-col-reverse px-6 my-10 space-y-8 lg:my-0 lg:flex-row lg:space-x-20 lg:space-y-8 lg:justify-center">
        <div className="hidden lg:inline-flex mt-[10rem]">
          <img className="" src="/image4.png" alt="img" />
        </div>
        <div className="flex flex-col space-y-4 justify-evenly">
          <p
            className="text-[#3289FB] tracking-widest text-base lg:mt-[10rem] mt-[10rem]"
            style={{
              fontFamily: "'Permanent Marker', cursive ",
            }}
          >
            Nurturing confidence, creativity, & personal growth{" "}
          </p>
          <h1 className="mt-2 leading-10 text-[#000] font-open-sans text-3xl font-bold">
            Through our innovative
            <br /> theatre program
          </h1>
          <p className="mr-4 lg:mx-0 leading-8 lg:w-[30rem] mt-[12px] text-[#62677F] font-open-sans lg:text-sm text-base font-normal">
            In our classes, we have experienced actors as teachers, and we offer
            acting courses with practical training and support, allowing kids to
            learn acting much more quickly.
          </p>

          <div className=" flex flex-col lg:flex-row mt-[2rem] justify-start lg:space-x-0">
            <div className="block lg:hidden">
              <img className="" src="/image4.png" alt="img" />
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex space-x-2 lg:mr-6">
                <div className="bg-[#F15C71] lg:rounded-full rounded-lg lg:w-8 lg:h-8 w-12 h-8 flex items-center justify-center">
                  <TiTick className="text-white" fill="#fff" />
                </div>
                <p className="text-[#000] font-open-sans text-base font-bold">
                  Dance & movement
                </p>
              </div>
              <div className="flex space-x-2">
                <div className="bg-[#7254E8] lg:rounded-full rounded-lg lg:w-8 lg:h-8 w-12 h-8 flex items-center justify-center">
                  <TiTick className="text-white" fill="#fff" />
                </div>
                <p className="text-[#000] font-open-sans text-base font-bold">
                  Puppetry
                </p>
              </div>
            </div>
            <br />
            <div className="flex flex-col space-y-6">
              <div className="flex space-x-2">
                <div className="bg-[#F69323] lg:rounded-full rounded-lg lg:w-8 lg:h-8 w-12 h-8 flex items-center justify-center">
                  <TiTick className="text-white" fill="#fff" />
                </div>
                <p className="text-[#000] font-open-sans text-base font-bold">
                  Rhythm & speaking
                </p>
              </div>
              <div className="flex space-x-2">
                <div className="bg-[#06C3C3] lg:rounded-full rounded-lg lg:w-8 lg:h-8 w-12 h-8 flex items-center justify-center">
                  <TiTick className="text-white" fill="#fff" />
                </div>
                <p className="text-[#000] font-open-sans text-base font-bold">
                  Improvising & acting
                </p>
              </div>
            </div>
          </div>
          <div className="text-center lg:w-[15rem]">
            <a href="/ContactUs">
              <button className="text-base mt-[3rem] lg:rounded-full rounded-xl text-white font-medium bg-[#191E24] lg:px-16 lg:py-2 w-full py-4">
                Contact us
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="flex lg:items-end">
        <a
          href="https://www.instagram.com/actkidz_education/"
          className="fixed right-0 z-10 bottom-24 lg:bottom-32 hover:scale-105"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/R60.png" alt="img" className="w-[6rem] lg:w-[8rem]" />
        </a>
        <a
          href="//api.whatsapp.com/send?phone=8686983890&text=Hi, i would like to know more about Actkidz"
          className="fixed right-0 z-10 bottom-5 hover:scale-105"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img src="/R40.png" alt="img" className="w-[6rem] lg:w-[8rem]" />
        </a>
      </div>
    </>
  );
};

export default Program;
