import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";

const Gallery = () => {
  const defaultPhotos = [
    "G0.png",
    "G01.png",
    "G1.png",
    "G2.png",
    "G3.png",
    "G4.png",
    "G5.png",
    "G6.png",
    "G7.png",
    "G8.png",
    "G9.png",
    "G10.png",
  ];
  const [imageList, setImageList] = useState(defaultPhotos);
  let images = [
    "G0.png",
    "G01.png",
    "G1.png",
    "G2.png",
    "G3.png",
    "G4.png",
    "G5.png",
    "G6.png",
    "G7.png",
    "G8.png",
    "G9.png",
    "G10.png",
  ];

  const [displayCount, setDisplayCount] = useState(0);

  const loadMore = () => {
    setDisplayCount((prevCount) => prevCount + 4);
  };
  useEffect(() => {
    const getHomeData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/getgalleryData`);
        const photos = response.data.data?.Photos;
        if (
          Array.isArray(photos) &&
          photos.length > 0 &&
          response?.data?.data?.Published
        ) {
          setImageList(photos);
        } else {
          setImageList(defaultPhotos);
        }
      } catch (e) {
        console.log("err", e);
      }
    };

    getHomeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pt-20 md:pt-32 flex flex-col items-center mx-6 lg:mx-0 md:flex-row justify-center md:space-x-2 space-y-2 my-5">
        <div className="space-y-2">
          {/* First Column Images */}
          <img
            className="h-auto max-w-full rounded-lg"
            src={imageList[0]}
            alt=""
          />
          <img
            className="h-auto max-w-full rounded-lg"
            src={imageList[1]}
            alt=""
          />
        </div>
        <div>
          {/* Second Column Images */}
          <div className="flex flex-col md:flex-row md:space-x-2 space-y-1">
            <img
              className="h-auto max-w-full rounded-lg"
              src={imageList[2]}
              alt=""
            />
            <img
              className="h-auto max-w-full rounded-lg"
              src={imageList[3]}
              alt=""
            />
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 space-y-2">
            <img
              className="my-1 h-auto max-w-full rounded-lg"
              src={imageList[4]}
              alt=""
            />
            <div className="space-y-2">
              <img
                className="h-auto max-w-full rounded-lg"
                src={imageList[5]}
                alt=""
              />
              <div className="flex flex-col items-center justify-center">
                <img src="/c5.png" alt="c5" />
                <p
                  style={{
                    color: "#0568EC",
                    textAlign: "center",
                    fontFamily: "Figma Hand",
                    fontSize: "20px",
                    fontStyle: "italic",
                    fontWeight: "700",
                    lineHeight: "150%",
                    letterSpacing: "-0.22px",
                  }}
                  className="w-full md:w-[350px]"
                >
                  Your children will absolutely love our drama classes
                </p>
              </div>
            </div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={imageList[6]}
              alt=""
            />
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 space-y-1">
            <img
              className="h-auto max-w-full rounded-lg"
              src={imageList[7]}
              alt=""
            />
            <img
              className="h-auto max-w-full rounded-lg"
              src={imageList[8]}
              alt=""
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:w-[810px] space-x-2 space-y-1">
            <div className="my-2">
              <img
                className="h-auto w-full md:w-[400px] rounded-lg"
                src={imageList[9]}
                alt=""
              />
            </div>
            <div className="flex flex-col space-y-2">
              <img
                className="h-auto max-w-full rounded-lg"
                src={imageList[10]}
                alt=""
              />
              <img
                className="h-auto max-w-full rounded-lg"
                src={imageList[11]}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center space-x-2 space-y-2">
        {images.slice(0, displayCount).map((imageSrc, index) => (
          <div
            key={index}
            className={`rounded-lg overflow-hidden shadow-lg ${
              index < imageList.length
                ? "initial-image-class"
                : "additional-image-class"
            }`}
          >
            <img
              src={imageSrc}
              alt={`Gallery  ${index}`}
              className="h-[200px] max-w-full rounded-lg"
            />
          </div>
        ))}
      </div>

      {displayCount < images.length && (
        <div className="flex flex-col mt-4">
          <p
            className="text-black font-normal tracking-widest text-center cursor-pointer"
            style={{ fontFamily: "'Permanent Marker', cursive" }}
            onClick={loadMore}
          >
            Load more...
          </p>
        </div>
      )}
    </>
  );
};

export default Gallery;
