import React from "react";

const Testimonial = () => {
  return (
    <div
      className="lg:py-[10rem]"
      style={{ backgroundImage: `linear-gradient(to bottom, #f5f7fa, white)` }}
    >
      <h1 className="text-2xl lg:hidden text-center text-gray-900 font-semibold font-['Plus Jakarta Sans']">
        Testimonial
      </h1>
      <div className="flex flex-col lg:flex-row justify-center lg:items-center lg:space-x-4  p-4 lg:p-10">
        <img
          className="rounded-lg w-full lg:w-auto lg:h-96"
          src="/test.png"
          alt="testimonial"
        />
        <div className="bg-gray-100/20 relative lg:flex lg:flex-row lg:items-center">
          <div className="bg-[#ffff] shadow-md lg:px-4 py-4 ml-4 px-4 -mt-[3rem] lg:mt-[5rem] lg:-mb-[4rem] lg:-ml-[6rem] lg:z-20 inline-block rounded-md font-bold font-open-sans text-black text-sm lg:absolute lg:-top-16">
            Management
          </div>
          <div className="mb-2 px-6 rounded-lg lg:bg-white shadow-xl lg:w-[507px] h-auto lg:py-6 lg:-ml-[4.6rem] lg:z-10 mt-[3rem]">
            <p className="text-[#62677F] text-base font-normal">
              "ActKidz's is a standout for us. The holistic approach, safe
              expression, and expert-led classes make it an excellent choice for
              our child's development. Highly recommended!"
            </p>
            <span className="mt-2 pb-[2rem] underline font-bold text-black text-lg lg:hidden block">
              Highly recommended!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
