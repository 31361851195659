import React, { useState } from "react";
import Modal from "./Modal";
import { useLocation } from "react-router-dom";

const Holistic = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const isAboutRoute = location.pathname === "/about";
  const isOurProgram = location.pathname === "/OurProgram";
  const items = [
    {
      imgSrc: "/M8.png",
      label: "Holistic Development",
      description:
        "ActKidz theatre programme focuses not only on honing acting skills but also on fostering holistic development in children and teens. The curriculum is carefully designed to enhance self-esteem, promote team-building skills, and instill the splendid craft of acting.",
    },
    {
      imgSrc: "/M2.png",
      label: "Personality Development",
      description:
        "Beyond acting techniques, the program is dedicated to shaping the personalities of participants. It aims to prepare children to express themselves freely and confidently in various aspects of life, emphasizing the importance of self-expression without fear of judgment. Safe Environment for Expression: Acting and improvisation in the classes",
    },
    {
      imgSrc: "/M3.png",
      label: "Safe environment for expression",
      description:
        "Acting and improvisation in the classes provide a safe space for children to express themselves and their emotions without the fear of being judged or ridiculed. This promotes a positive and nurturing environment for personal growth. ",
    },
    {
      imgSrc: "/M4.png",
      label: "Professional Guidance",
      description:
        "The classes are led by experienced actors who serve as teachers. This ensures that children receive professional guidance, allowing them to learn the art of acting more efficiently. Practical training and support are integral components of the courses",
    },
    {
      imgSrc: "/M6.png",
      label: "National Education Policy 2020",
      description:
        "The programme aligns with the National Education Policy 2020, which places a specific emphasis on nurturing the creative potential of each individual. By identifying and supporting creative abilities from a young age, ActKidz aims to help children realize their creative potential to the fullest.",
    },
    {
      imgSrc: "/M9.png",
      label: "Early creative training",
      description:
        "Recognizing the importance of early creative training, ActKidz encourages parents to provide their creatively inclined children with the necessary tools for success. The program is designed to give children a head start in developing their creative abilities and preparing them for a future that values creativity and expression.",
    },
  ];
  const openModal = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };
  return (
    <>
      <div
        className={`lg:pt-[10rem] ${
          isAboutRoute || isOurProgram ? "bg-transparent " : "bg-[#f5f7fa]"
        }`}
      >
        <div className="pt-[4rem] lg:pt-0">
          <h1
            className=" text-[#0568EC] tracking-widest text-center text-sm font-bold"
            style={{
              fontFamily: "'Permanent Marker', cursive ",
            }}
          >
            Our Key Highlights
          </h1>
          <p className="mt-[13px] text-[#000] text-3xl font-bold font-open-sans text-center mx-4">
            Unlocking Potential Through ActKidz
          </p>
        </div>
        <div
          className="w-full"
          style={{
            background:
              isAboutRoute || isOurProgram
                ? "none"
                : "linear-gradient(0deg, rgba(230, 240, 254, 0.50) 0%, rgba(217, 217, 217, 0.00) 102.29%)", // Your gradient background
          }}
        >
          <div className="grid grid-cols-3 md:grid-cols-6 lg:flex lg:justify-center lg:space-x-8 p-4 lg:ml-0 pb-[2rem]">
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => openModal(item)}
                className="hover:scale-105 hover:transition-all flex flex-col items-center justify-center"
              >
                <div
                  className="shadow shadow-[#0D90D529] lg:w-[120px] lg:h-[120px] h-[80px] w-[80px] rounded-full overflow-hidden flex items-center justify-center bg-white "
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={item.imgSrc}
                    alt={item.label}
                    className="object-contain rounded-full w-[9rem] h-[9rem] p-4"
                  />
                </div>
                <p className="cursor-pointer w-[6rem] lg:w-[8rem] text-[#000] lg:text-sm text-xs font-open-sans font-bold mt-2 text-center">
                  {item.label}
                </p>
              </div>
            ))}
            <Modal
              modalOpen={modalOpen}
              selectedItem={selectedItem}
              closeModal={closeModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Holistic;
