import React from "react";

const Benefit = () => {
  return (
    <>
      <div className="lg:hidden text-center">
        <a href="/ourprogram">
          <button className="mt-[2rem] md:mb-[2rem] text-center text-white font-open-sans text-sm rounded-xl bg-[#0568EC] lg:px-24 lg:py-5 px-[6rem] py-4">
            Explore all Programs
          </button>
        </a>
      </div>
      <div className="hidden lg:flex lg:flex-col bg-[#0568EC] w-full ">
        <img
          className="w-[10rem] h-[5rem] lg:mt-[3rem]"
          src="/p2.png"
          alt="img"
        />
        <div className="flex justify-center items-center w-full">
          <div className="flex justify-self-center">
            {" "}
            <p className="text-[#fff] text-center font-open-sans text-2xl font-bold">
              Benefits of our classes
            </p>
          </div>
        </div>

        <div className="flex flex-row justify-center space-x-10 mt-[2rem]">
          <div className="flex flex-col items-center ml-[2rem]">
            <img
              className="w-[72px] h-[72px] bg-[#0568EC]"
              src="/r1.png"
              alt="Boost confidence"
            />
            <p className="lg:w-[12rem] text-[#fff] text-center font-open-sans text-base font-semibold mt-2">
              Boost your self-esteem and confidence.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              className="w-[72px] h-[72px] bg-[#0568EC]"
              src="/r2.png"
              alt="Community"
            />
            <p className="lg:w-[12rem] text-[#fff] text-center font-open-sans text-base font-semibold mt-2">
              Sense of community and make new friends.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              className="w-[72px] h-[72px] bg-[#0568EC]"
              src="/r3.png"
              alt="Communication skills"
            />
            <p className="lg:w-[12rem] text-[#fff] text-center font-open-sans text-base font-semibold mt-2">
              Communication skills, both verbal and nonverbal.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              className="w-[72px] h-[72px] bg-[#0568EC]"
              src="/r4.png"
              alt="Public speaking"
            />
            <p className="lg:w-[12rem] text-[#fff] text-center font-open-sans text-base font-semibold mt-2">
              Enhance your kids public speaking
            </p>
          </div>
        </div>
        <div className="lg:flex lg:self-end">
          <img
            className="w-[14rem] h-[9rem] mt-[1rem]"
            src="/p1.png"
            alt="img"
          />
        </div>
      </div>

      <div className="lg:hidden  mx-[1.5rem]">
        {/* First Section - Image followed by Text */}
        <div className="flex flex-row items-center text-center">
          <img className="mt-[5rem] mb-[2rem]" src="/D1.SVG" alt="img" />
          <p className="text-[#000] mt-5 font-open-sans text-4xl font-bold">
            Benefits of our classes
          </p>
          <img className="mt-6" src="/D2.SVG" alt="img" />
        </div>
        {/* <div className="flex flex-col items-center text-center mt-6">
          <img className="mt-6" src="/D2.SVG" alt="img" />
        </div> */}
        <div className="mt-6 space-y-4">
          <div className="flex flex-row items-center space-x-6 ">
            <img
              className="w-[32px] h-[32px] "
              src="/p5.png"
              alt="Public speaking"
            />
            <p className="text-[#353845] font-open-sans text-base font-semibold mt-2">
              Enhance your kids public speaking
            </p>
          </div>
          <div className="flex flex-row items-center  space-x-6">
            <img
              className="w-[32px] h-[32px] "
              src="/p3.png"
              alt="Boost confidence"
            />
            <p className="text-[#353845] font-open-sans text-base font-semibold mt-2">
              Boost your self-esteem and confidence.
            </p>
          </div>
          <div className="flex flex-row items-center  space-x-6">
            <img className="w-[32px] h-[32px] " src="/p4.png" alt="Community" />
            <p className="text-[#353845] font-open-sans text-base font-semibold ">
              Sense of community and make new friends.
            </p>
          </div>
          <div className="flex flex-row items-center  space-x-6">
            <img
              className="w-[32px] h-[32px] "
              src="/p6.png"
              alt="Communication skills"
            />
            <p className="text-[#353845] font-open-sans text-base font-semibold mt-2">
              Communication skills, both verbal and nonverbal.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefit;
