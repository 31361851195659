import React from "react";
import Body from "../Body";
import Program from "../program";
import Courses from "../courses";
import Benefit from "../Benefit";
import Heighlight from "../Heighlight";
import Testimonial from "../Testimonial";
import Contact from "../Contact";
const Home = () => {
  return (
    <>
      <div className="pt-20 md:pt-32 overflow-x-hidden">
        <Body />
        <Program />
        <Courses />
        <Benefit />
        <Heighlight />
        <Testimonial />
      </div>
      <Contact />
    </>
  );
};

export default Home;
